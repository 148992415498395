<!DOCTYPE html>
<html lang="es">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet" type="text/css" href="vercertificado.component.html" media="all" />
    <link rel="stylesheet" type="text/css" href="vercertificado.component.html" media="print" />
    <title>Document</title>

</head>

<body>
    <div class="container" id="certificado" *ngFor="let user of Usuario">
        <div class="row mx-auto" style="padding-top: 25px;">
            <div class="col mx-auto">
                <img [src]="viewPhoto.path ||'../../assets/istl000.png'" class="card-img-top img-fluid">
            </div>
            <div class="col  mx-auto" >
                <img [src]="viewPhoto2.path ||' ../../assets/SENESCYT.png'" class="card-img-top img-fluid">
            </div>
            <div class="col mx-auto">
                <img [src]="viewPhoto3.path ||' ../../assets/GADVILCA.png'" class="card-img-top img-fluid">
            </div>
        </div>
        <div class="row" style="padding-top: 50px;">
            <div class="col-md mx-auto">
                <h1 class="text-center" style="font-size:50px;">CERTIFICA</h1>
                <h1 class="text-center" style="font-size: 30px;">A</h1>
                <h1 class="text-center" style="font-size:30px;">{{user.names}}</h1>
                <br>
                <p class="text-center" style="font-weight:normal;font-size:30px;">Por haber asistido a la Capacitación
                    de {{user.name_carrer}} bajo el
                    Proyecto “{{user.name_project}}”,<br> en el barrio {{user.barrio}}, parroquia {{user.parroquia}},
                    del Cantón Loja,
                    con una duración de {{user.horas}} horas.</p>
                <br>
                <br>
                <br>

            </div>
        </div>
        <div class="row mx-auto" id="firmas">
            <div class="col" id="firmaIzquierda">
                ________________________________<br>
                Dr. Richard Ruiz Ordoñez<br>
                RECTOR DEL <br>
                SUPERIOR TECNOLOGICO LOJA
            </div>
            <div class="col" id="firmaDerecha">
                ________________________________<br>
                Carlos Ortiz<br> PREFECTO DEL GOBIERNO<br>
                DE VILCABAMBA
            </div>
        </div>
        <br>
        <h2 class="fecha text-center"> Loja, {{fechaactual}}</h2>
        <div id="codigo">
            <b>{{user.codigo}}</b>
        </div><br>
        <form class="btns" [formGroup]="form_image" id="selecImage">
            <div class="row mx-auto">
                <div class="col-md-4 mx-auto">
                    <button type="submit" class="btn btn-dark" (click)="print()">Imprimir</button>
                </div>
                <div class="col-md-3 ">
                    <button type="submit" class="btn btn-dark" (click)="volver()">Volver</button>
                </div>
            </div><br>
            <label>Aquí puedes cambiar las imagnes</label>
            <div class="row">
                <div class="col-md-3 ">
                    <select class="form-control" [(ngModel)]="Image._id" formControlName="_id"
                        (change)="getOnePhoto($event)" required>
                        <option *ngFor="let img of Images" selected [value]="img._id">{{img.nombre}}</option>
                    </select>
                </div>
                <div class="col-md-3 ">
                    <select class="form-control" [(ngModel)]="Image._id" formControlName="_id"
                        (change)="getOnePhoto2($event)" required>
                        <option *ngFor="let imga of Images" selected [value]="imga._id">{{imga.nombre}}</option>
                    </select>
                </div>
                <div class="col-md-3 ">
                    <select class="form-control" [(ngModel)]="Image._id" formControlName="_id"
                        (change)="getOnePhoto3($event)" required>
                        <option *ngFor="let imge of Images" selected [value]="imge._id">{{imge.nombre}}</option>
                    </select>
                </div>
            </div>
        </form>
        <br>

    </div>

    <div *ngIf="Usuario.length===0">
        <div class="text-center" style="padding-top: 250px;">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</body>

</html>